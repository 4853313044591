<template>
  <v-card v-if="$can('product_category_list', 'product_category')">
    <v-card-title
      v-if="$can('product_category_save', 'product_category')"
      class="d-flex align-center flex-wrap pb-0"
    >
      <div class="d-flex align-center pb-5">
        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          @click="editItem()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>新增一级分类</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :hide-default-footer="true"
        :items-per-page="pages.itemsPerPage"
        :server-items-length="pages.serverItemsLength"
        :headers="getHeader()"
        no-data-text="无数据"
        :items="categorys"
      >
        <template #[`item.name`]="{ item }">
          <span v-if="item.parentId != 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {{ `${item.name}` }}
        </template>

        <template
          #[`item.isEnabled`]="{ item }"
        >
          <v-switch
            v-model="item.isEnabled"
            dense
            x-small
            :label="item.isEnabled ? `显示`: `禁用`"
            @change="toggleEnabled(item)"
          ></v-switch>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="$can('product_category_manage', 'product_category')"
            text
            dense
            x-small
            color="primary"
            @click="toProductManage(item)"
          >
            管理商品
          </v-btn>
          <span v-if="item.parentId === 0 ">
            <v-btn
              v-if="$can('product_category_save', 'product_category')"
              text
              dense
              x-small
              color="primary"
              @click="addSubCategory(item)"
            >
              添加子分类
            </v-btn>
          </span>
          <v-btn
            v-if="$can('product_category_update', 'product_category')"
            text
            dense
            x-small
            color="primary"
            @click="editItem(item)"
          >
            编辑
          </v-btn>
          <v-btn
            v-if="$can('product_category_delete', 'product_category')"
            text
            dense
            x-small
            color="primary"
            @click="showDeleteDialog(item)"
          >
            删除
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}分类</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>分类名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="name"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        required
                        counter="25"
                        label="商品分类名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="showCategoryMenu"
                      cols="12"
                      md="2"
                    >
                      <label for="parentName">上级分类：</label>
                    </v-col>
                    <v-col
                      v-if="showCategoryMenu"
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-show="false"
                        id="parentId"
                        v-model="editedItem.parentId"
                      ></v-text-field>
                      <v-text-field
                        id="href"
                        v-model="parentName"
                        :rules="[]"
                        counter="25"
                        label="上级分类"
                        readonly
                        outlined
                        clearable
                        dense
                        @click="treeDialog = true"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="orderNum">排序：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="orderNum"
                        v-model="editedItem.sort"
                        label="排序"
                        type="Number"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="description"><span style="color: red">*</span>描述：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-textarea
                        v-model="editedItem.description"
                        outlined
                        name="input-7-4"
                        label="描述"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>

      <TreeSelect
        v-model="treeDialog"
        :active="active"
        :selected-tree-data="selectedTreeData"
        title="选择上级菜单"
        @getActive="getActive"
      ></TreeSelect>
    </v-navigation-drawer>

    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 是否删除 {{ deleteData.name }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem(id)"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import TreeSelect from '@/components/select/TreeSelect'
import CategoryApi from '@/api/product/category'

export default {
  name: 'ProductCategory',
  components: {
    TreeSelect,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      pages: {
        itemsPerPage: -1,
        serverItemsLength: 0,
      },
      rules: {
        required,
      },
      categorys: [],
      editDialog: false,
      treeDialog: false,
      iconDialog: false,
      deleteDialog: false,
      editedItem: {},
      defaultItem: {
        parentId: 0,
        sort: 1,
        name: '',
        description: '',
      },
      loadings: {
        submitLoading: false,
      },
      selectedTreeData: [],
      parentName: '',
      selectNode: [],
      active: [],
      deleteData: {},
      showCategoryMenu: false,
      commitFlag: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '分类名称', value: 'name' },
        { text: '排序', value: 'sort' },
        { text: '商品数量', value: 'productCount' },
        { text: '创建时间', value: 'createdTime' },
      ]

      if (this.$can('product_category_enabled', 'product_category')) {
        baseHeader.push({ text: '显示', value: 'isEnabled' })
      }
      if (this.$can('product_category_update', 'product_category')
          || this.$can('product_category_save', 'product_category')
          || this.$can('product_category_delete', 'product_category')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    async editItem(item) {
      this.active = []
      if (item) {
        if (item.parentId === 0) {
          this.showCategoryMenu = false
        } else {
          this.showCategoryMenu = true
        }

        this.editedItem = item

        // 通过遍历找到items下中被嵌套的数据
        if (item.parentId !== 0) {
          const node = this.findMenuNode(this.editedItem.parentId, this.selectedTreeData)
          this.parentName = node.name
          const obj = {
            id: item.parentId,
          }
          this.active.push(obj)
        }
      } else {
        this.showCategoryMenu = false
        this.initEditedItem()
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    addSubCategory(item) {
      this.initEditedItem()
      this.$nextTick(() => {
        this.$refs.editForm.resetValidation()
        this.editedItem.parentId = item.id
        this.showCategoryMenu = false
      })
      this.editDialog = true
    },
    deleteItem() {
      CategoryApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadData()
      })
    },
    getActive(val) {
      this.selectNode = val
      if (val.length > 0) {
        this.parentName = val[0].name
        this.editedItem.parentId = val[0].id
      } else {
        this.parentName = ''
        this.editedItem.parentId = 0
      }
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return

      if (this.commitFlag) {
        return
      }
      this.commitFlag = true
      CategoryApi.saveOrUpdate(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.closeDialog()
        this.commitFlag = false
        this.loadData()
      })
    },
    toggleEnabled(item) {
      const saveData = {
        id: item.id,
        isEnabled: item.isEnabled,
      }
      CategoryApi.toggleEnabled(saveData).then(res => {
        this.$toast.success('修改成功！')
      })
    },
    loadData() {
      if (this.$can('product_category_list', 'product_category')) {
        CategoryApi.listAll().then(res => {
          this.categorys = res.data.data
          this.pages.serverItemsLength = this.categorys.length
        })
      }
      if (this.$can('product_category_update_get', 'product_category')) {
        CategoryApi.getNodeList().then(res => {
          const { data } = res.data
          this.selectedTreeData = data
        })
      }
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.parentName = ''
      this.editDialog = false
    },
    findMenuNode(id, menu) {
      if (menu.length === 0) {
        return
      }
      let node
      for (let i = 0; i < menu.length; i++) {
        const item = menu[i]
        if (item.id === id) {
          node = item
          break
        }
        if (item.children && item.children.length > 0) {
          node = this.findMenuNode(id, item.children)
        }
      }

      return node
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.name = ''
      this.defaultItem.description = ''
      this.defaultItem.parentId = 0
      this.defaultItem.sort = 1
      this.parentName = ''
      this.editedItem = this.defaultItem
    },
    showDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
    toProductManage(item) {
      this.$router.push({ path: '/product', query: { categoryId: item.id } })
    },
  },
}
</script>
